<template>
    <v-form
        ref="form"
        v-model="formValid"
        class="form"
        :readonly="loading"
    >
        <v-container>
            <div
                class="header"
                data-cy="content-header"
            >
                <span class="text-h4 font-weight-bold">{{ title }}</span>
                <p class="text-h6 font-weight-light grey--text text--darken-2">
                    {{ subtitle }}
                </p>
            </div>
            <v-row dense>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <v-text-field
                        id="nome"
                        v-model="nome"
                        label="Nome"
                        data-cy="nome"
                        rounded
                        outlined
                        flat
                        required
                        :error-messages="nomeErrors"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <v-text-field
                        id="sobrenome"
                        v-model="sobrenome"
                        label="Sobrenome"
                        data-cy="sobrenome"
                        rounded
                        outlined
                        required
                        :error-messages="sobrenomeErrors"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <v-text-field
                        id="cpf"
                        v-model="cpf"
                        v-mask="['###.###.###-##']"
                        data-cy="cpf"
                        rounded
                        outlined
                        label="CPF"
                        :loading="cpfLoading"
                        :disabled="cpfLoading"
                        :success-messages="success.cpf"
                        :error-messages="cpfErrors"
                        @keyup.prevent="checkCpf()"
                    />
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                >
                    <v-text-field
                        id="telefone"
                        v-model="telefone"
                        v-mask="['(##) #####-####', '(##) ####-####']"
                        data-cy="telefone"
                        rounded
                        outlined
                        label="Telefone"
                        :error-messages="telefoneErrors"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        id="email"
                        v-model="email"
                        data-cy="email"
                        rounded
                        outlined
                        label="Email"
                        :success-messages="success.email"
                        :loading="emailLoading"
                        :disabled="emailLoading"
                        :error-messages="emailErrors"
                        @blur.prevent="checkEmail()"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        id="confirmar-email"
                        v-model="confirmarEmail"
                        data-cy="confirmar-email"
                        rounded
                        outlined
                        label="Confirmar Email"
                        :error-messages="confirmarEmailErrors"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        id="senha"
                        v-model="senha"
                        rounded
                        data-cy="senha"
                        outlined
                        label="Senha"
                        type="password"
                        :error-messages="senhaErrors"
                    />
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        id="confirmar-senha"
                        v-model="confirmarSenha"
                        data-cy="confirmar-senha"
                        rounded
                        outlined
                        label="Confirmar Senha"
                        type="password"
                        :error-messages="confirmarSenhaErrors"
                    />
                </v-col>
            </v-row>
            <v-row
                slot="buttons"
                dense
                justify="space-between"
            >
                <v-col
                    cols="auto"
                    class="mx-auto mx-sm-0"
                >
                    <v-btn
                        rounded
                        filled
                        outlined
                        height="62px"
                        width="162px"
                        class="text-capitalize font-weight-light text-h6 return-step"
                        data-cy="return-step"
                        elevation="3"
                        x-large
                        :disabled="loading || cpfLoading || emailLoading"
                        @click.prevent="returnStep()"
                    >
                        <v-icon left>
                            mdi-arrow-left
                        </v-icon>
                        Voltar
                    </v-btn>
                </v-col>
                <v-col
                    cols="auto"
                    class="mx-auto mx-sm-0"
                >
                    <v-btn
                        rounded
                        filled
                        color="primary"
                        height="62px"
                        width="162px"
                        class="text-capitalize font-weight-light text-h6 next-step"
                        data-cy="next-step"
                        elevation="3"
                        x-large
                        :disabled="$v.$invalid || !formValid || cpfLoading || emailLoading"
                        :loading="loading"
                        @click.prevent="nextStep()"
                    >
                        Continuar
                        <v-icon right>
                            mdi-arrow-right
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import { required, sameAs, email, minLength } from "vuelidate/lib/validators";
import {
    cpf,
    nome,
    hasUpperCase,
    hasLowerCase,
    hasDigit,
} from "../validators/customValidators";
import { mask } from "vue-the-mask";
import { checkAPI } from "../services/services";

export default {
    name: "UsuarioForm",
    components: {},
    directives: {
        mask,
    },
    props: {
        title: {
            default: "Dados Pessoais",
            type: String,
        },
        subtitle: {
            default: "Preencha o formulário com seus dados pessoais",
            type: String,
        },
    },
    data() {
        return {
            formValid: false,
            success: {
                cpf: [],
                email: [],
            },
            emailErrors: [],
            confirmarEmailErrors: [],
            telefoneErrors: [],
            nomeErrors: [],
            cpfLoading: false,
            emailLoading: false,
            cpfErrors: [],
            sobrenomeErrors: [],
            senhaErrors: [],
            confirmarSenhaErrors: [],
            loading: false,
        };
    },
    validations: {
        nome: {
            required,
            nome,
            minLength: minLength(3),
        },
        sobrenome: {
            required,
            nome,
            minLength: minLength(3),
        },
        cpf: {
            required,
            cpf,
        },
        email: {
            required,
            email,
        },
        confirmarEmail: {
            required,
            sameAsEmail: sameAs("email"),
        },
        telefone: {
            required,
            minLength: minLength(14),
        },
        senha: {
            required,
            hasUpperCase,
            hasLowerCase,
            hasDigit,
            minLength: minLength(8),
        },
        confirmarSenha: {
            required,
            sameAsSenha: sameAs("senha"),
        },
    },
    computed: {
        nome: {
            get() {
                return this.$store.getters["usuario/nome"];
            },
            set(value) {
                this.$store.commit("usuario/nome", value);
            },
        },
        email: {
            get() {
                return this.$store.getters["usuario/email"];
            },
            set(value) {
                this.$store.commit("usuario/email", value);
            },
        },
        confirmarEmail: {
            get() {
                return this.$store.getters["usuario/confirmarEmail"];
            },
            set(value) {
                this.$store.commit("usuario/confirmarEmail", value);
            },
        },
        telefone: {
            get() {
                return this.$store.getters["usuario/telefone"];
            },
            set(value) {
                this.$store.commit("usuario/telefone", value);
            },
        },
        cpf: {
            get() {
                return this.$store.getters["usuario/cpf"];
            },
            set(value) {
                this.$store.commit("usuario/cpf", value);
            },
        },
        sobrenome: {
            get() {
                return this.$store.getters["usuario/sobrenome"];
            },
            set(value) {
                this.$store.commit("usuario/sobrenome", value);
            },
        },
        senha: {
            get() {
                return this.$store.getters["usuario/senha"];
            },
            set(value) {
                this.$store.commit("usuario/senha", value);
            },
        },
        confirmarSenha: {
            get() {
                return this.$store.getters["usuario/confirmarSenha"];
            },
            set(value) {
                this.$store.commit("usuario/confirmarSenha", value);
            },
        },
    },
    watch: {
        nome() {
            this.nomeErrors = [];
            this.$v.nome.nome ? null : this.nomeErrors.push("Nome Inválido");
            this.$v.nome.minLength ? null : this.nomeErrors.push("Nome muito curto");
            this.$v.nome.required ? null : this.nomeErrors.push("Campo Obrigatório");
        },
        sobrenome() {
            this.sobrenomeErrors = [];
            this.$v.sobrenome.nome
                ? null
                : this.sobrenomeErrors.push("Nome Inválido");
            this.$v.nome.minLength
                ? null
                : this.sobrenomeErrors.push("Nome muito curto");
            this.$v.sobrenome.required
                ? null
                : this.sobrenomeErrors.push("Campo Obrigatório");
        },
        cpf() {
            this.cpfErrors = [];
            this.success.cpf = "";
            this.$v.cpf.cpf ? null : this.cpfErrors.push("CPF Inválido");
            this.$v.cpf.required ? null : this.cpfErrors.push("Campo Obrigatório");
        },
        email() {
            this.emailErrors = [];
            this.success.email = "";
            this.$v.email.email ? null : this.emailErrors.push("Email Inválido");
            this.$v.email.required
                ? null
                : this.emailErrors.push("Campo Obrigatório");
        },
        confirmarEmail() {
            this.confirmarEmailErrors = [];
            this.$v.confirmarEmail.required
                ? null
                : this.confirmarEmailErrors.push("Campo Obrigatório");
            this.$v.confirmarEmail.sameAsEmail
                ? null
                : this.confirmarEmailErrors.push("Email não confere");
        },
        senha() {
            this.senhaErrors = [];
            this.$v.senha.required
                ? null
                : this.senhaErrors.push("Campo Obrigatório");
            this.$v.senha.hasUpperCase
                ? null
                : this.senhaErrors.push("Ao menos uma letra maiúscula");
            this.$v.senha.hasLowerCase
                ? null
                : this.senhaErrors.push("Ao menos uma letra minúscula");
            this.$v.senha.hasDigit
                ? null
                : this.senhaErrors.push("Ao menos um dígito");
            this.$v.senha.minLength
                ? null
                : this.senhaErrors.push("Deve ter no mínimo 8 caracteres");
        },
        confirmarSenha() {
            this.confirmarSenhaErrors = [];
            this.$v.confirmarSenha.required
                ? null
                : this.confirmarSenhaErrors.push("Campo Obrigatório");
            this.$v.confirmarSenha.sameAsSenha
                ? null
                : this.confirmarSenhaErrors.push("Senha não confere");
        },
        telefone() {
            this.telefoneErrors = [];
            this.$v.telefone.required
                ? null
                : this.telefoneErrors.push("Campo Obrigatório");
            this.$v.telefone.minLength
                ? null
                : this.telefoneErrors.push("Número inválido");
        },
    },
    methods: {
        async nextStep() {
            if (this.$v.$invalid || !this.formValid) return;
            this.$store.commit("nextStep");
        },
        returnStep() {
            this.$store.commit("returnStep");
        },
        async checkCpf() {
            if (this.$v.cpf.$invalid) return;
            this.cpfLoading = true;
            try {
                const cpfRaw = this.cpf.replace(/\D/g, "");
                const response = await checkAPI.checkCPF(cpfRaw);
                if (response.data.success === false)
                    this.cpfErrors.push(response.data.msg);
                if (response.data.success === true) this.success.cpf = "CPF disponível";
            } catch (error) {
                console.log(error)
                console.log(error.response)
                console.log(error.response.status)
                if (error.response.status === 429) {
                    this.cpfErrors.push("Muitas requisições, aguarde um momento");
                    this.success.cpf = "";
                }
            } finally {
                this.cpfLoading = false;
            }
        },
        async checkEmail() {
            if (this.$v.email.$invalid) return;
            this.emailLoading = true;
            try {
                const response = await checkAPI.checkEmail(this.email);
                if (response.data.success === false)
                    this.emailErrors.push(response.data.msg);
                if (response.data.success === true)
                    this.success.email = "Email disponível";
            } catch (error) {
                if (error.response.status === 429) {
                    this.emailErrors.push("Muitas requisições, aguarde um momento");
                    this.success.email = "";
                }
            } finally {
                this.emailLoading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 40px;
}
</style>
